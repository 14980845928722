import { defineStore } from 'pinia'

const HTTP_CUSTOMER_EXISTS = 409
const OPC_CONFIG = 'embed=bundle_items,expert_reviews,robots,redirect_url,params'
const ADDRESS_TYPE = {
  SHIPPING: 'shippingAddress',
  BILLING: 'billingAddress'
}

let savedAddress = { [ADDRESS_TYPE.SHIPPING]: null, [ADDRESS_TYPE.BILLING]: null }

export const useOpcStore = defineStore('opc', {
  state () {
    return {
      product: {
        product_id: null,
        campaign_id: null,
        images: {},
        details: {},
        final_price: 0,
        original_price: 0,
        nutritional_data: {},
        reference_unit_contents: 0,
        description: null,
        manufacturer_name: null,
        name: null,
        awards: [],
        bundle_items: [],
        params: {},
        stocks: [
        {
          name: null,
          is_enabled: true,
          is_in_stock: true
        }
        ]
      },
      calculated: {
        isBundle: false,
        quantitySelectList: [],
        images: {
          image: {},
          smallImage: {}
        },
        price: {
          totalSavings: 0
        },
        tags: [],
        packageQty: null,
      },
      customer: {
        [ADDRESS_TYPE.SHIPPING]: { ref: null, customer_address_id: null },
        [ADDRESS_TYPE.BILLING]: { ref: null, customer_address_id: null },
        shippingAge: { ref: null, day: null, month: null, year: null },
        differentBillingAddress: { value: false },
        email: { ref: null, value: null },
        agreements: { ref: null, agb: null, newsletter: null },
        payment: { ref: null, id: null, tokenized_nonce: null },
        // only validated
        couponCode: { ref: null, value: null }
      },
      payment: {
        inProgress: false,
        availableMethods: [],
        // will be set to false on component mount. Used to indicate braintree SDK errors
        error: true,
        data: {},
        selectedMethod: ''
      },
      updating: false,
      pendingOrder: false,
      selectedQty: 1,
      backendError: '',
      formsValid: false
    }
  },
  getters: {
    stock: ({ product: { stocks } }) => stocks?.[0] ?? {},
    selectedPaymentMethod: ({ payment: { selectedMethod } }) => selectedMethod,
    paymentError: ({ payment: { error } }) => error,
    paymentMethods: ({ payment: { availableMethods } }) => availableMethods,
    paymentInProgress: ({ payment: { inProgress } }) => inProgress,
    params: ({ product: { params } }) => params,
    grandTotal: ({ selectedQty, calculated: { price: { finalPrice } }, product: { stocks } }) => selectedQty * finalPrice + stocks?.[0].shipping_costs,
  },
  actions: {
    async refreshProduct () {
      this.updating = true
      const { ApiController, $router } = await _getModules()

      try {
        const { currentRoute: { params: { pId, cId } } } = $router
        
        const data = await ApiController.fetchProduct(pId, cId, OPC_CONFIG)
        await _checkProduct(data)

        this.product = data
        await this.refreshCalculatedFields()

        _trackView(data)
        this.updating = false
      } catch (e) {
        console.error(e)
        this.updating = false
        $router.replace({ name: 'error' })
      }
    },
    async refreshCalculatedFields () {
      const { UtilityHelper, createPriceObject, SelectOptionFactory, PRODUCT_TYPES } = await _getModules()

      const images = {
        image: UtilityHelper.prefixImage(this.product.images.image, true),
        smallImage: UtilityHelper.prefixImage(this.product.images.small_image)
      }

      _preloadImages(images)

      const price = await createPriceObject(this.product)
      price.totalSavings = (this.selectedQty * this.product.original_price) - (this.selectedQty * this.product.final_price)
      const tags = this.product.tags ? this.product.tags.split(', ') : []
      const quantitySelectList = SelectOptionFactory.getQtyOptionsForProduct(this.product, false, 1)
      const packageQty = await _countPackageQty(this.product)
      const isBundle = (this.product?.type ?? '') === PRODUCT_TYPES.BUNDLE

      this.calculated = {
        quantitySelectList,
        price,
        tags,
        images,
        packageQty,
        isBundle
      }
    },
    validateForms(highlight = true) {
      // map representing the precedence of the elements
      const { shippingAddress, billingAddress, shippingAge, email, agreements, payment, couponCode } = this.customer
      const validationMap = [shippingAddress, billingAddress, email, shippingAge, payment, couponCode, agreements]
      const offset = document.getElementById('opc-header').offsetHeight +
        (document.getElementById('opc-stepper')?.offsetHeight ?? 5)

      let firstError = null

      validationMap.forEach(({ ref }) => {
        if (ref?.validate() ?? true) return

        firstError = firstError ?? ref

        if (highlight) return
        ref?.resetValidation()
      })

      this.formsValid = !firstError

      if (!(highlight && firstError)) return

      setTimeout(async () => {
        const { $el: element } = firstError
        const { DomHelper } = await _getModules()

        if(DomHelper.elementInViewport(element, offset)) return

        DomHelper.scrollIntoView(element, offset)
      }, 0)
    },
    replaceBackendError ({ e, message } = { message: '' }) {
      this.backendError = message
      if (!e) {
        return
      }
      console.error(e)
    },
    async placeOrder (validateForm = true) {
      this.replaceBackendError()

      if (validateForm) {
        this.validateForms()
        if (!this.formsValid) return
      }

      this.pendingOrder = true

      const { UtilityHelper, TranslationsHelper } = await _getModules()

      if (!await _getCustomer(this.customer, this.placeOrder)) {
        this.pendingOrder = false
        return
      }

      if (await this.saveAddresses() && await this.savePaymentMethod()) {
        try {
          void _subscribeNewsletter(this.customer.agreements.newsletter)

          const uuid = await _createCartWithProduct(this.product, this.selectedQty)
          const { redirectUrl } = await _placeOrder({ customer: this.customer, uuid, product: this.product })

          location.replace(redirectUrl)
          return
        } catch (e) {
          if (e.status === 400) {
            this.replaceBackendError({
              e,
              message: UtilityHelper.formatString(
                TranslationsHelper.translate('error_message_payment_not_available'),
                e.cause
              )
            })
          } else {
            this.replaceBackendError({ e, message: 'create_order_error' })
          }
          console.error(e)
          UtilityHelper.noticeNRError(e, 'Failed to place OPC order: ')
        }
      }

      this.pendingOrder = false
    },
    setCustomerProperty({ type, data }) {
      const previous = this.customer[type] ?? {}
      this.customer[type] = { ...previous, ...data }
    },
    setPaymentInProgress (value) {
      this.payment.inProgress = value
    },
    async savePaymentMethod () {
      this.payment.inProgress = true

      try {
        const verification = await _verifyPayment(this.payment)
        const data = await _saveAndSecurePayment(
          {
            selectedQty: this.selectedQty,
            calculated: this.calculated,
            customer: this.customer,
            payment: this.payment
          },
          verification)

        this.setCustomerProperty({ type: 'payment', data })
        this.payment.inProgress = false

        return true
      } catch (e) {
        this.payment.inProgress = false

        const { ApiController } = await _getModules()

        try {
          await ApiController.logPaymentError(this.payment.selectedMethod, e)
        } catch (e) {
          console.error(e)
        }

        this.replaceBackendError({ e, message: 'opc_payment_challenge_error' })

        return false
      }
    },
    async getAddresses () {
      const { ApiController, customerStore } = await _getModules()
      await customerStore.waitUntilPending()

      if (!customerStore.customerActive) return

      const addresses = await ApiController.fetchCustomerAddresses()

      const shipping = addresses.find(address => address.is_default_shipping_address)
      const billing = addresses.find(address => address.is_default_billing_address) ?? null

      this.setCustomerProperty({ type: ADDRESS_TYPE.SHIPPING, data: shipping })
      this.setCustomerProperty({ type: ADDRESS_TYPE.BILLING, data: billing })

      // stash for later
      savedAddress = { [ADDRESS_TYPE.SHIPPING]: shipping, [ADDRESS_TYPE.BILLING]: billing }
    },
    async saveAddresses () {
      try {
        const dob = _getDob(this.customer)
        if (!this.customer.differentBillingAddress.value || _addressesAreTheSame(this.customer[ADDRESS_TYPE.SHIPPING], this.customer[ADDRESS_TYPE.BILLING])) {
          const shippingAddressId = await _saveAddress(this.customer.shippingAddress, dob, ADDRESS_TYPE.SHIPPING)
          this.setCustomerProperty({ type: ADDRESS_TYPE.SHIPPING, data: shippingAddressId })

          return true
        }

        const [shippingAddressId, billingAddressId] = await Promise.all([
          await _saveAddress(this.customer.shippingAddress, dob, ADDRESS_TYPE.SHIPPING),
          await _saveAddress(this.customer.billingAddress, dob, ADDRESS_TYPE.BILLING)
        ])
        this.setCustomerProperty({ type: ADDRESS_TYPE.SHIPPING, data: shippingAddressId })
        this.setCustomerProperty({ type: ADDRESS_TYPE.BILLING, data: billingAddressId })

        return true
      } catch (e) {
        this.replaceBackendError({ e, message: 'save_address_error' })

        return false
      }
    },
    async refreshPaymentMethods () {
      const { ApiController } = await _getModules()
      this.payment.availableMethods = _sortPaymentMethods(await ApiController.fetchAvailablePaymentMethods())
    },
    updatePaymentData ({ error, data }) {
      this.payment.error = error ?? null
      this.payment.data = data ?? {}
      this.validateForms(false)
    },
    updateCustomerInfo (payload) {
      this.setCustomerProperty(payload)
      this.validateForms(false)
    },
    async updateSelectedQty(qty) {
      this.selectedQty = qty
      this.validateForms(false)
      await this.refreshCalculatedFields()
    },
    setSelectedPaymentMethod (method) {
      this.payment.selectedMethod = method
      this.payment.error = null
      this.payment.data = {}
      this.validateForms(false)
    }
  },
})

const _getCustomer = async (customer, hook) => {
  const { UtilityHelper, customerStore, MessageHelper } = await _getModules()
  if (!customerStore.customerActive) {
    try {
      const address = customer.differentBillingAddress.value ? customer.billingAddress : customer.shippingAddress
      await customerStore.register(
        {
          email: customer.email.value,
          firstName: address.first_name,
          lastName: address.last_name,
          gender: address.gender,
          title: address.title,
          dob: _getDob(customer)
        }
      )
    } catch (e) {
      if (e.status === HTTP_CUSTOMER_EXISTS) {
        customerStore.showLogin({ visible: true, email: customer.email.value, hook })
        return
      }
      console.error(e)
      UtilityHelper.noticeNRError(e, 'Failed to register OPC customer: ')
      MessageHelper.addErrorMessage('validation_msg_email')
    }
  }

  return customerStore.customerActive
}

const _checkProduct = async ({ is_opc: isOpc, is_enabled: enabled }) => {
  const { customerStore, $router } = await _getModules()
  await customerStore.waitUntilPending()

  if (!isOpc) {
    $router.replace($router.currentRoute.fullPath.replace('-opc-', '-pdp-'))
    return
  }

  if (enabled || customerStore.customerIsAdmin) return

  throw new Error('Disabled product or PDP')
}

const _trackView = ({ product_id: productId, campaign_id: campaignId, params = {} }) => {
  setTimeout(async () => {
    const { TrackingHelper } = await _getModules()
    TrackingHelper.sendUtmParams({ ...params, productId, campaignId })
    TrackingHelper.productView(productId, campaignId)
  }, 0)
}

const _saveAddress = async ({ customer_address_id: id, ...address }, dob, type = false) => {
  const original = savedAddress[type]

  if (id && _addressesAreTheSame(original, address)) return id

  const { ApiController } = await _getModules()
  const data = {
    first_name: address.first_name,
    last_name: address.last_name,
    city: address.city,
    country: address.country,
    company: address.company,
    zip: address.zip,
    gender: address.gender,
    street_name: address.street_name,
    street_number: address.street_number,
    dob: dob,
    is_default_shipping_address: 0,
    is_default_billing_address: 0
  }
  return await ApiController.saveCustomerAddress(data)
}

const _getDob = (customer) => {
  const { day, month, year } = customer.shippingAge
  if (!day || !month || !year) return null
  return `${year}-${month}-${day}`
}

const _addressesAreTheSame = (addr1, addr2) => {
  if (!addr1 || !addr2) return false
  const keys = Object.keys(addr1).filter(key => key !== 'ref' && key !== 'customer_address_id')

  return keys.every(key => addr1[key] === addr2[key])
}

const _placeOrder = async ({ customer, uuid, product }) => {
  const { ApiController, TrackingHelper } = await _getModules()
  const { payment, shippingAddress, billingAddress, differentBillingAddress: { value: different }, couponCode } = customer

  const payload = {
    cart_uuid: uuid,
    shipping_address_id: shippingAddress.customer_address_id,
    billing_address_id: different ? billingAddress.customer_address_id : shippingAddress.customer_address_id,
    payment_method_id: payment.id,
    braintree_nonce: payment.tokenized_nonce,
    coupon_code: couponCode.value ?? null,
  }

  return await ApiController.placeOrder(payload, await TrackingHelper.getUtmParams(product.params))
}

const _verifyPayment = async ({ selectedMethod, data: paymentData }) => {
  if (selectedMethod === 'paypal') {
    return { tokenized_nonce: paymentData.tokenized_nonce }
  }
  if (selectedMethod === 'credit_card') {
    return { tokenized_nonce: paymentData.nonce }
  }
  return paymentData
}

const _saveAndSecurePayment = async (
  { selectedQty, calculated, customer, payment: { data: paymentData, selectedMethod } },
  verification
) => {
  const payload = {
    method_code: selectedMethod,
    is_default_payment_method: false,
    ...verification
  }
  const { threeDSecureCheck, ApiController } = await _getModules()
  const { data } = await ApiController.savePaymentMethod(payload)

  if (selectedMethod !== 'credit_card') return { ...data, ...verification }

  // credit card, 3DS
  const { data: { braintree_method_nonce: nonce, ip_address: ipAddress } } = await ApiController.fetchBraintreeMethodNonce({ token: data.token })
  const { shippingAddress, differentBillingAddress: { value: different }, email: { value: email } } = customer
  const billingAddress = different ? customer.billingAddress : shippingAddress

  const { nonce: tokenizedNonce } = await threeDSecureCheck({
    shippingAddress,
    billingAddress,
    nonce,
    email,
    amount: calculated.price.finalPrice * selectedQty,
    bin: paymentData.details.bin,
    ipAddress: ipAddress
  })

  return { ...data, tokenized_nonce: tokenizedNonce }
}

const _createCartWithProduct = async (product, qty) => {
  const { ApiController, UtilityHelper } = await _getModules()

  const uuid = UtilityHelper.uuid()
  const payload = { product_id: product.product_id, campaign_id: product.campaign_id, qty }

  await ApiController.addItemToCart(uuid, payload)
  return uuid
}

const _sortPaymentMethods = (methods) => {
  const purchaseOrder = 'purchase_order'
  const filtered = methods.filter(item => item !== purchaseOrder)

  if (filtered.length === methods.length ) return methods

  return [purchaseOrder, ...filtered]
}

const _countPackageQty = async ({ type, bundle_items: bundleItems }) => {
  const { PRODUCT_TYPES } = await _getModules()

  if (type !== PRODUCT_TYPES.BUNDLE) return 1
  return bundleItems.reduce((quantity, { unit_type: unit, shipping_qty: shippingQty, qty }) =>
    'bottle' === unit ? quantity + (shippingQty * qty) : quantity, 0)
}

const _subscribeNewsletter = async (newsletterAccepted) => {
  if (!newsletterAccepted) return
  const { ApiController } = await _getModules()

  await ApiController.subscribeNewsletter()
}

const _preloadImages = (images) => {
  const image = new Image()
  const smallImage = new Image()
  image.src = images.image.src
  smallImage.src = images.smallImage.src
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { PRODUCT_TYPES },
      { default: SiteConfig },
      { default: ApiController },
      { default: UtilityHelper },
      { default: TrackingHelper },
      { default: TranslationsHelper },
      { default: MessageHelper },
      { default: DomHelper },
      { default: SelectOptionFactory },
      { createPriceObject },
      { default: $router },
      { threeDSecureCheck },
      { useCustomerStore }
    ] = await Promise.all([
      await import('@/constants/GlobalConstants'),
      await import('@/services/SiteConfig'),
      await import('@/services/ApiController'),
      await import('@/services/helpers/UtilityHelper'),
      await import('@/services/helpers/TrackingHelper'),
      await import('@/services/helpers/TranslationsHelper'),
      await import('@/services/helpers/MessageHelper'),
      await import('@/services/helpers/DomHelper'),
      await import('@/modules/select_option/SelectOptionFactory'),
      await import('@/services/helpers/ProductHelper'),
      await import('@/router'),
      await import('@/services/Braintree'),
      await import('@/stores/customer'),
    ])

    cache = {
      PRODUCT_TYPES,
      ApiController,
      UtilityHelper,
      SiteConfig,
      TrackingHelper,
      TranslationsHelper,
      MessageHelper,
      DomHelper,
      SelectOptionFactory,
      createPriceObject,
      $router,
      threeDSecureCheck,
      customerStore: useCustomerStore()
    }
    return cache
  }
})()
